<template>
  <div>
    <van-field v-model="currentText" :label="elProps.label" :placeholder="elProps.placeholder" :disabled="elProps.disabled" :readonly="true" :required="required" :rules="rules" :inputAlign="elProps.inputAlign" is-link @click="openPopup">
      <template #left-icon>
        <div v-if="config.tip">
          <van-popover v-model="pageData.tipPopover" placement="top-start" theme="dark">
            <div class="h-font-sm h-p-4">{{ config.tip }}</div>
            <template #reference>
              <van-icon name="info" color="#57ccc2" @click.stop="handleTip" />
            </template>
          </van-popover>
        </div>
        <slot name="left-icon"></slot>
      </template>
      <template #right-icon>
        <slot name="right-icon"><van-icon v-if="currentText && elProps.close" name="clear" color="#c8c9cc" size="16" @click.stop="handleEmpty"/></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-picker ref="picker" show-toolbar :title="elProps.label" :columns="options" :value-key="elProps.labelKey" @confirm="handleConfirm" @cancel="handleCancel"> </van-picker>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from "@/api/service";
import { findIndex, isArray } from "lodash";
import mixins from "./mixins";
export default {
  name: "HSelect",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    pageForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    },
    currentText: {
      handler(newVal, oldVal) {
        if (this.config.relName && this.form) {
          this.form[this.config.relName] = newVal;
        }
      }
    },
    "config.dictCode": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.initDict(newVal);
        }
      }
    },
    "config.options": {
      handler(newVal, oldVal) {
        this.options = newVal;
      }
    }
  },
  data() {
    return {
      pageData: {
        show: false,
        tipPopover: false
      },
      val: null,
      options: []
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          show: true,
          readonly: false,
          disabled: false,
          close: false,
          options: [],
          valueKey: "value",
          labelKey: "label",
          clearable: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请选择"
        },
        this.config
      );
    },
    currentText: {
      get() {
        if (!(this.val && this.options && this.options.length > 0)) {
          return null;
        }
        const textOption = this.options.filter(item => {
          return item[this.elProps.valueKey] === this.val;
        });
        if (textOption && textOption.length > 0) {
          return textOption[0][this.elProps.labelKey];
        } else {
          return null;
        }
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
      if (this.config.dictCode) {
        this.initDict(this.config.dictCode);
      } else if (this.config.api) {
        request({ url: this.config.api.url, method: this.config.api.method || "get" }).then(res => {
          if (isArray(res)) {
            this.$set(this, "options", res || []);
            this.config.options = res || [];
          }
        });
      } else {
        this.$set(this, "options", this.elProps.options);
      }
    },
    initDict(dictCode) {
      this.$set(this, "options", this.pageContext.dictMap[dictCode]);
    },
    handleEvent(eventType) {
      const event = this.config[eventType + "Event"];
      eventType === "confirm" && this.$emit("confirm", this.val);
      if (event) {
        if (typeof event === "string" || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this);
        } else if (event instanceof Function) {
          event(this);
        }
      }
    },
    openPopup() {
      if (this.elProps.disabled || this.elProps.readonly) {
        return false;
      }
      this.pageData.show = true;
      const index = findIndex(this.options, [this.elProps.valueKey, this.val]);
      this.$nextTick(() => {
        this.$refs.picker.setColumnIndex(0, index);
      });
    },
    handleConfirm(data) {
      this.pageData.show = false;
      if (!data) {
        return false;
      }
      this.val = data[this.elProps.valueKey];
      // this.currentText=data[this.elProps.labelKey]
      this.config.val = this.val;
      this.$emit("input", this.val);
      this.handleEvent("confirm");
    },
    handleCancel() {
      this.pageData.show = false;
    },
    handleTip() {
      this.pageData.tipPopover = !this.pageData.tipPopover;
    },
    handleEmpty() {
      this.val = null;
      this.config.val = this.val;
      this.$emit("input", this.val);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .van-popover {
  width: 90%;
}
</style>
