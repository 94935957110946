<template>
  <div>
    <slot name="slot"></slot>
  </div>
</template>
<script>
import mixins from "./mixins";
export default {
  name: "HSlot",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    }
  },
  data() {
    return {
      val: null
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          readonly: true,
          disabled: false,
          show: true
        },
        this.config
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
    }
  }
};
</script>
