<template>
  <div class="container">
    <template v-for="formItem in pageLayout">
      <div v-if="formItem.fmCode !== 'images'" :key="formItem.fmCode" class="form h-shadow-sm">
        <div class="form-title">
          <span class="title">{{ formItem.fmName }}</span>
        </div>
        <div class="form-content">
          <HYForm :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]"> </HYForm>
        </div>
      </div>
    </template>
    <div class="h-flex" style="margin-top: 10px;margin-bottom: 10px;padding: 0px 5px;">
      <van-button round :loading="loading" class="h-flex-1" type="primary" style="margin: 10px;" @click="confirm">确定</van-button>
      <van-button round plain class="h-flex-1" type="primary" style="margin: 10px;" @click="cancel">取消</van-button>
    </div>
  </div>
</template>
<script>
import HYForm from "@/components/Form/index.vue";
import { request } from "@/api/service";
import scriptLib from "@/views/product/layout/orderScript";
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  name: "OrderDispatch",
  components: { HYForm },
  provide() {
    return {
      pageContext: this,
      pageForm: this.pageForm,
      pageLayout: this.pageLayout,
      pageLayoutMap: this.pageLayoutMap,
      PageScript: this.PageScript
    };
  },
  data() {
    return {
      loading: false,
      driverId: null,
      dispatchId: null,
      orderId: null,
      PageScript: scriptLib.call(this),
      pageForm: { rescue: {} },
      pageLayoutMap: {},
      pageLayout: [
        {
          id: "3654f39a-4926-4e1d-b4a7-b47b072a8df4",
          componentId: "61bc337361cda9549152fd00",
          fmCode: "rescue",
          fmName: "派单信息",
          formItems: [
            {
              component: "HText",
              prop: "rescueAddr",
              label: "救援地址",
              type: "textarea",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b555"
            },
            {
              component: "HText",
              prop: "destinationAddr",
              label: "救援目的地",
              type: "textarea",
              show: "pageForm.rescue && pageForm.rescue.projectCode && pageForm.rescue.projectCode.charAt(0) === 'T'",
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b666"
            },
            {
              component: "HText",
              prop: "predictKm",
              label: "预估公里数",
              show: "pageForm.rescue && pageForm.rescue.projectCode && pageForm.rescue.projectCode.charAt(0) === 'T'",
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b777"
            },
            {
              component: "HText",
              prop: "driverMessage",
              label: "司机留言",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b775"
            },
            {
              component: "HSelect",
              prop: "driverId",
              label: "分配司机",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "请选择分配司机",
                  trigger: "onChange"
                }
              ],
              val: null,
              relName: "driverName",
              api: {
                url: "/acs-rescue/rscCompanyDriver/queryDriverByCompany",
                method: "POST"
              },
              valueKey: "id",
              labelKey: "name",
              confirm: async (val, formItems) => {
                if (!val) {
                  this.pageForm.rescue.driverMobile = "";
                  this.pageForm.rescue.plateNo = "";
                  return;
                }
                const res = await request({
                  url: "/acs-rescue/rscCompanyDriver/info/" + val
                });
                formItems
                  .filter(fi => fi.prop === "driverId")[0]
                  .options.map(op => {
                    if (op.id === val) {
                      this.$set(this.pageForm.rescue, "driverName", op.name);
                    }
                  });
                this.$set(this.pageForm.rescue, "driverMobile", res.mobile);
                this.$set(this.pageForm.rescue, "plateNo", res.plateNo);
              },
              id: "ce9d14db-e12d-4699-abe1-c3be893f70d0"
            },
            {
              component: "HInput",
              prop: "driverMobile",
              label: "司机手机号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b779"
            },
            {
              component: "HInput",
              prop: "plateNo",
              label: "司机车牌号",
              show: true,
              disabled: false,
              view: true,
              col: 12,
              rules: [],
              val: null,
              id: "6b872407-a72d-49d5-b7df-51de4cf7b772"
            },
            {
              component: "HDatePicker",
              prop: "obtainTime",
              label: "接单时间",
              show: true,
              disabled: false,
              readonly: true,
              view: true,
              col: 12,
              rules: [],
              val: null,
              filters: "dateFormat()",
              format: "YYYY-MM-DD HH:mm",
              maxOpt: [],
              isLink: false,
              id: "8b15382c-1296-4b6c-b74b-878a9b34ff38"
            },
            {
              component: "HDatePicker",
              prop: "orderTime",
              label: "下单时间",
              show: true,
              disabled: false,
              readonly: true,
              view: true,
              col: 12,
              rules: [],
              val: null,
              filters: "dateFormat()",
              format: "YYYY-MM-DD HH:mm",
              maxOpt: [],
              isLink: false,
              id: "8b15382c-1296-4b6c-b74b-878a9b34ff39"
            }
          ],
          labelWidth: 120,
          initTooltipShow: false
        }
      ],
      detailForm: {}
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("user", ["info"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    await this.initData();
    this.init();
  },
  methods: {
    dayjs,
    async confirm() {
      let data = this.pageForm.rescue;
      if (!this.pageForm.rescue.driverId) {
        this.$notify({ type: "danger", message: `请选择分配司机` });
        return;
      }
      if (this.driverId === data.driverId) {
        this.$notify({ type: "danger", message: `该司机已派单` });
        return;
      }
      this.loading = true;
      data.orderId = this.orderId;
      data.id = this.dispatchId;
      try {
        await request({
          url: "/acs-rescue/order/dispatch/dispatchDriver",
          method: "post",
          data
        });
        this.$notify({ type: "success", message: `已派单` });
        this.cancel();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.$router.replace({
        path: "/product/order/list"
      });
    },
    async init() {
      const { id, dispatchId } = this.$route.query;
      const res = await request({
        url: "/acs-rescue/order/downInfo/" + id
      });
      this.$set(this.pageForm, "rescue", { ...res, ...res.orderDispatch });
      this.detailForm = { rescue: { ...res, ...res.orderDispatch } };
      this.dispatchId = dispatchId;
      this.driverId = res.driverId;
      this.orderId = res.id;
    },
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
::v-deep .van-checkbox {
  display: inline-block;
}
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    .title {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.fail-reason {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: 微软雅黑;
  color: rgb(102, 102, 102);
  line-height: 30px;
  padding: 0px 20px;
  word-wrap: break-word;
  padding-bottom: 20px;
}
.submitBtn {
  height: 44px;
  .fixed {
    border-top: 1px solid $border-color;
    position: fixed;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.agreement ::v-deep {
  line-height: 12px;
  .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
}
.none-tip {
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
</style>
