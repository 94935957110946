<template>
  <div>
    <van-field v-model="currentText" v-bind="{ ...$attrs, ...elProps }" :required="required" :rules="rules">
      <template #right-icon>
        <slot name="right-icon"><van-button icon="guide-o" type="primary" size="mini" @click="showMap">定位</van-button></slot>
      </template>
      <template #extra>
        <slot name="extra"></slot>
      </template>
    </van-field>

    <van-popup v-model="pageData.show" round position="bottom" :safe-area-inset-bottom="true">
      <div class="van-picker__toolbar">
        <button type="button" class="van-picker__cancel" @click="handleCancel">取消</button>
        <div class="van-ellipsis van-picker__title">{{ elProps.label }}</div>
        <button type="button" class="van-picker__confirm" @click="handleConfirm">确认</button>
      </div>
      <div :style="mapStyle">
        <BaiduMap
          :style="mapStyle"
          :ak="elProps.ak"
          :map-click="false"
          :center="center"
          :zoom="zoom"
          :scroll-wheel-zoom="true"
          @click="getClickInfo"
          @ready="onBaiduMapReady"
          @moving="syncCenterAndZoom"
          @moveend="syncCenterAndZoom"
          @zoomend="syncCenterAndZoom"
        >
          <BmView style="width: 100%;" />
          <BmMarker :position="{ lng: center.lng, lat: center.lat }" :dragging="true" animation="BMAP_ANIMATION_BOUNCE" />
          <BmControl v-if="elProps.showKeyword" :offset="{ width: '10px', height: '10px' }">
            <BmAutoComplete v-model="keyword" :sug-style="{ zIndex: 999999 }">
              <van-search v-model="keyword" placeholder="请输入关键词" />
            </BmAutoComplete>
          </BmControl>
          <BmGeolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" show-address-bar auto-location />
          <BmLocalSearch :keyword="keyword" :auto-viewport="true" :panel="false" />
        </BaiduMap>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { BaiduMap, BmControl, BmView, BmAutoComplete, BmLocalSearch, BmMarker, BmGeolocation } from "vue-baidu-map";
import { set, get } from "lodash";
import mixins from "./mixins";
export default {
  name: "HBaiduMap",
  mixins: [mixins],
  components: {
    BaiduMap,
    BmControl,
    BmView,
    BmAutoComplete,
    BmLocalSearch,
    BmMarker,
    BmGeolocation
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    pageForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.init();
      }
    }
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: [],
          label: null,
          append: null,
          show: true,
          size: null,
          readonly: true,
          disabled: false,
          clearable: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请选择",
          ak: process.env.VUE_APP_BAIDU_MAPKEY,
          lngKey: "lng",
          latKey: "lat",
          showKeyword: true
        },
        this.config
      );
    },
    currentText: {
      get() {
        if (this.config.val && this.config.val.length > 1) {
          return this.config.val[0] + "--" + this.config.val[1];
        } else {
          return "";
        }
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  data() {
    return {
      pageData: {
        show: false
      },
      // 在子组件中使用 props 选项去接收来自父组件传递过来的数据
      BMap: null, // 地图组件是否就绪
      showMapComponent: true,
      keyword: "", // 地区搜索的信息
      mapStyle: { width: "100%", height: "80vh" },
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      choosedLocation: { province: "", city: "", district: "", addr: "" },
      val: null
    };
  },
  methods: {
    get,
    set,
    init() {
      if (this.config.val && this.config.val.length > 1) {
        this.center.lng = this.config.val[0];
        this.center.lat = this.config.val[1];
      }
      if (this.form[this.elProps.lngKey] && this.form[this.elProps.latKey]) {
        this.center.lng = this.form[this.elProps.lngKey];
        this.center.lat = this.form[this.elProps.latKey];
      }
    },
    showMap() {
      this.init();
      this.pageData.show = true;
    },
    // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
    onBaiduMapReady(e) {
      console.log("返回BMap类和map实例", e);
      const that = this;
      this.BMap = e.BMap;
      if (this.BMap) {
        // 获取定位地址信息并赋值给声明变量
        // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch() 方法详解 ：https://blog.csdn.net/zyz00000000/article/details/82774543
        const geolocation = new this.BMap.Geolocation();
        // 通过 getCurrentPosition() 方法：获取当前的位置信息
        geolocation.getCurrentPosition(res => {
          const { lng, lat } = res.point;
          const { province, city, district, street, street_number } = res.address;

          that.center = { lng, lat };
          that.choosedLocation = {
            province,
            city,
            district,
            addr: street + street_number,
            lng,
            lat
          };
        });
      }
    },

    /** *
     * 地图点击事件。
     */
    getClickInfo(e) {
      // 调整地图中心位置
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
      // 此时已经可以获取到BMap类
      if (this.BMap) {
        const that = this;
        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder();
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        geoCoder.getLocation(e.point, function(res) {
          const addrComponent = res.addressComponents;
          const surroundingPois = res.surroundingPois;
          const province = addrComponent.province;
          const city = addrComponent.city;
          const district = addrComponent.district;
          let addr = addrComponent.street;
          if (surroundingPois.length > 0 && surroundingPois[0].title) {
            if (addr) {
              addr += `-${surroundingPois[0].title}`;
            } else {
              addr += `${surroundingPois[0].title}`;
            }
          } else {
            addr += addrComponent.streetNumber;
          }
          that.choosedLocation = {
            province,
            city,
            district,
            addr,
            ...that.center
          };
        });
      }
    },
    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.zoom = e.target.getZoom();
    },
    /** *
     * 确认
     */
    handleConfirm() {
      this.pageData.show = false;
      this.config.val = [this.center.lng, this.center.lat];
      this.form[this.elProps.lngKey] = this.center.lng;
      this.form[this.elProps.latKey] = this.center.lat;
      this.$emit("input", this.config.val);
    },
    /** *
     * 取消
     */
    handleCancel() {
      this.pageData.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  width: 50px;
}
</style>
